import {createRouter, createWebHashHistory} from "vue-router";

const routes = [
    {
        path: '/',
        redirect: { name: 'Dashboard' }
    },
    {
        path: "/home",
        name: "home",
        component: () => import (
            "../views/Manager/Home.vue"),
        children: [
            {
                path: "/dashboard",
                name: "Dashboard",
                meta: {
                    title: '首页'
                },
                component: () => import ("../views/Manager/Dashboard.vue")
            },
            {
                path: "/news",
                name: "news",
                meta: {
                    title: '新闻管理'
                },
                component: () => import ("../views/Manager/News")
            },
            {
                path: "/case",
                name: "case",
                meta: {
                    title: '图书档案'
                },
                component: () => import (
                    "../views/Manager/Cases")
            },
            {
                path: "/product",
                name: "product",
                meta: {
                    title: '图书编目'
                },
                component: () => import ("../views/Manager/Product")
            },
            {
                path: '/contactus',
                name: 'contactus',
                component: () => import('../views/Manager/Contactus')
            },
            {
                path: '/aboutsy',
                name: 'aboutsy',
                component: () => import('../views/Manager/Aboutsy')
            },
            {
                path: '/detail',
                name: 'detail',
                component: () => import('../views/Manager/Details')
            },
        ]
    },
    {
        path: "/login",
        name: "Login",
        meta: {
            title: '登录'
        },
        component: () => import (
        /* webpackChunkName: "login" */
        "../views/Manager/Login.vue")
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});
// 未登录则跳转到首页
// router.beforeEach((to, from, next) => {
//     document.title = `${to.meta.title} | 三阅后台管理系统`;
//     const role = localStorage.getItem('token');
//     const time=localStorage.getItem('tokentime')
//         console.log(new Date().getTime()-time)
//     if (!role && to.path !== '/login') {
//         next('/login');
//     } else if (to.path !== '/login' && new Date().getTime()-time>27000000){
//         next('/login');
//     }else{
//         next();
//     }
// });

export default router;