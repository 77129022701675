<template>
    <router-view />
</template>

<script>
export default {
  //暴露父页面的方法
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      isReloadData: true,
    }
  },
  methods: {
    //刷新事件
    reload() {
      this.isReloadData = false;
      this.$nextTick(() => {
        this.isReloadData = true;
      });
    }
  }
};

</script>

